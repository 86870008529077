.pie {
    height: 150px;
    width: 150px;
}

.prisoners-count {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // margin-right: 10px;
    width: 50%;

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        color: #000000;
        margin-bottom: 30px;
    }

    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 52px;
        line-height: 24px;
    }
}