:root {
	--brand-blue: #00A3FF;
	--light-blue: #f1fcfd;
	--secondary-light-blue: #e0f8fb;
	--triteary-txt: #c2c4ca;
	--txt-primary: #002b48;
	--txt-secondary: #787f95;
	--stroke-input: #eef0f4;
	--primary-red: #fb0220;
	--light-red: #ffedf1;
	--primary-green: #00c98d;
	--light-green: #edfffa;
	--brand-yellow: #ffcc2a;
	--light-yellow: #fffaea;
	--primary-yellow: #ffcc2a;
	--background: #f6f8fa;
	--menu-z-index: 1000;
	--layout-z-index: 1100;
	--overlay-z-index: 1200;
	--modal-z-index: 1300;
	--filter-z-index: 1300;
	--modal-opacity: 0;
	--modal-pointer-events: none;
}