.menu {
	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		width: 100%;
		height: 250px;
		padding: 0px;
		padding-bottom: 30px;

		background-color: #fff;
		border-top-color: #fff;
		border-top-style: solid;
		border-top-width: 4px;
		border-radius: 6px;
		box-shadow: 0 4px 10px rgb(90 119 149 / 8%);

		transition: 0.1s ease-in-out;
	}

	&__item:hover {
		box-shadow: 0 0 20px 10px rgb(90 119 149 / 12%);
	}

	&__item_granted {
		border-top-color: var(--brand-blue);
	}

	&__item_partially-granted {
		border-top-style: var(--primary-yellow);
	}

	&__item-icon {
		width: 100%;
		height: 150px;
		object-fit: cover;
	}
}
