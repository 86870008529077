.btn {
	padding: 15px 25px;

	font-size: 14px;
	font-weight: 600;
	text-align: center;

	border-radius: 5px;

	transition: 0.1s linear;

	// Primary

	&__primary {
		color: #fff;

		background-color: var(--brand-blue);
		border: 1px solid var(--brand-blue);
		box-shadow: 0 10px 20px rgb(1 161 183 / 16%);
	}

	&__primary:hover {
		background: #028aca;
		border-color: #028aca;
		box-shadow: 0 10px 20px rgb(1 161 183 / 16%);
	}

	&__primary.btn_disabled {
		opacity: 1;
		background-color: #c8e5e9;
		border-color: #c8e5e9;
	}

	// Secondary

	&__secondary {
		color: var(--brand-blue);

		background-color: var(--light-blue);
		border: 1px solid var(--brand-blue);
	}

	&__secondary:hover {
		border-color: var(--secondary-light-blue);
	}

	&__secondary.btn_disabled {
		color: var(--triteary-txt);

		background-color: var(--stroke-input);
		border-color: var(--stroke-input);
	}

	// Status

	&_disabled {
		pointer-events: none;
		cursor: not-allowed;

		opacity: 0.6;
	}

	&_loading {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__spinner {
		display: block;

		width: 16px;
		height: 16px;
		margin-left: 10px;

		border: 3px solid rgb(199 199 199 / 60%);
		border-top: 3px solid #c7c7c7;
		border-radius: 100%;

		animation: loading 1s linear infinite;
	}

	// circled

	&__circled {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 38px;
		height: 38px;

		background-color: #fff;
		border: 1px solid var(--stroke-input);
		border-radius: 100%;
	}

	&__grey {
		background-color: var(--background);
		border: 2px solid var(--stroke-input);
		border-radius: 6px;
	}

	&_blue {
		border-color: var(--brand-blue);
	}

	&_blue svg path {
		fill: var(--brand-blue);
	}
}

@keyframes loading {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(360deg);
	}
}
