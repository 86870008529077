.status {
	width: max-content;
	padding: 8px 20px;

	font-size: 14px;
	font-weight: 500;
	text-align: center;
	white-space: nowrap;

	border-style: solid;
	border-width: 1px;
	border-radius: 100px;

	&_success {
		color: var(--primary-green);

		background-color: var(--light-green);
		border-color: var(--primary-green);
	}

	&_warning {
		color: var(--brand-yellow);

		background-color: var(--light-yellow);
		border-color: var(--brand-yellow);
	}

	&_danger {
		color: var(--primary-red);

		background-color: var(--light-red);
		border-color: var(--primary-red);
	}
}
