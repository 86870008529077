.auth {
	display: flex;
	align-items: center;
	flex-direction: column;
	min-height: 100vh;

	& > * {
		width: 50%;
	}

	&__logo{
		display: flex;
		align-items: center;
	}

	&__logo img{
		margin-right: 16px;
		width: 120px;
		height: 120px;
	}

	&__logo p{
		font-family: 'Roboto', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 23px;
		color: #000000;
		max-width: 450px;
	}


	&__header {
		display: flex;
		background-color:#F3F3F3 ;
		width: 100%;
		padding: 10px;
		margin-bottom: 30px;
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;

		padding: 50px 8%;
	}

	&__heading {
		max-width: 90%;
		margin-right: auto;
		margin-left: auto;

		text-align: center;
	}

	&__title {
		margin-bottom: 50px;
		margin-top: 20px;
		font-size: 30px;
		max-width: 400px;
		font-weight: 700;
		color: var(--txt-primary);
	}

	&__subtitle {
		line-height: 25px;
		color: var(--txt-secondary);
	}
}

.code-timer {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100px;
	height: 40px;
	padding: 8px 14px;

	border: 1px solid var(--stroke-input);
	border-radius: 44px;

	&__count {
		flex: 1 1 0;

		font-weight: 600;
		color: var(--txt-primary);
		text-align: center;
	}
}
