.avatar-upload {
	position: relative;

	width: max-content;

	&__inner {
		overflow: hidden;

		width: 170px;
		height: 200px;

		border: 1px solid var(--triteary-txt);
		border-radius: 10px;
	}

	&__inner img {
		width: 100%;
		height: 100%;

		object-fit: cover;
	}

	&__btn {
		position: absolute;
		right: 5px;
		bottom: 0;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 30px;
		height: 30px;

		background-color: #fff;
		border-radius: 100%;
	}

	&__btn svg {
		width: 13px;
		height: 13px;
	}
}

.file {
	&-upload {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		padding: 30px 50px;

		background: var(--light-green);
		border: 2px dashed var(--primary-green);
		border-radius: 6px;
	}

	&__zone {
		position: relative;

		display: flex;
		align-items: center;

		width: 100%;
		padding: 22px 16px;

		background-color: #fff;
		border-style: solid;
		border-width: 1.5px;
		border-radius: 6px;
	}

	&__zone svg {
		min-width: max-content;
	}

	&__zone_loading>svg path {
		fill: var(--brand-blue);
	}

	&__zone_success>svg path {
		fill: var(--primary-green);
	}

	&__zone_error>svg path {
		fill: var(--primary-red);
	}

	&__zone-image {
		font-size: 18px;
		font-weight: 600;
	}

	&__zone-size {
		font-size: 14px;
	}

	&__close {
		position: absolute;
		top: 15px;
		right: 15px;
	}

	&__close svg {
		width: 20px;
		height: 20px;
	}

	&__close svg path {
		stroke: var(--primary-green);
		stroke-width: 3px;
	}

	&__zone-uploading {
		overflow: hidden;

		width: 100%;
		height: 5px;

		background: var(--secondary-light-blue);
		border-radius: 35px;
	}

	&__zone-uploading div {
		background-color: var(--brand-blue);
	}

	&__zone_loading {
		color: var(--brand-blue);

		border-color: var(--brand-blue);
	}

	&__zone_success {
		color: var(--primary-green);

		border-color: var(--primary-green);
	}
}