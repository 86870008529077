//.spinner {
//	display: flex;
//	align-items: center;
//	justify-content: center;
//
//	width: 100%;
//	height: 100vh;
//
//	background-color: #fff;
//
//	&__inner {
//		position: relative;
//
//		display: inline-block;
//
//		width: 80px;
//		height: 80px;
//	}
//
//	&__inner div {
//		transform-origin: 40px 40px;
//
//		animation: spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
//	}
//
//	&__inner div::after {
//		content: " ";
//
//		position: absolute;
//
//		display: block;
//
//		width: 7px;
//		height: 7px;
//		margin: -4px 0 0 -4px;
//
//		background: red;
//		border-radius: 50%;
//	}
//
//	&__inner div:nth-child(1) {
//		animation-delay: -0.036s;
//	}
//
//	&__inner div:nth-child(1)::after {
//		top: 63px;
//		left: 63px;
//	}
//
//	&__inner div:nth-child(2) {
//		animation-delay: -0.072s;
//	}
//
//	&__inner div:nth-child(2)::after {
//		top: 68px;
//		left: 56px;
//	}
//
//	&__inner div:nth-child(3) {
//		animation-delay: -0.108s;
//	}
//
//	&__inner div:nth-child(3)::after {
//		top: 71px;
//		left: 48px;
//	}
//
//	&__inner div:nth-child(4) {
//		animation-delay: -0.144s;
//	}
//
//	&__inner div:nth-child(4)::after {
//		top: 72px;
//		left: 40px;
//	}
//
//	&__inner div:nth-child(5) {
//		animation-delay: -0.18s;
//	}
//
//	&__inner div:nth-child(5)::after {
//		top: 71px;
//		left: 32px;
//	}
//
//	&__inner div:nth-child(6) {
//		animation-delay: -0.216s;
//	}
//
//	&__inner div:nth-child(6)::after {
//		top: 68px;
//		left: 24px;
//	}
//
//	&__inner div:nth-child(7) {
//		animation-delay: -0.252s;
//	}
//
//	&__inner div:nth-child(7)::after {
//		top: 63px;
//		left: 17px;
//	}
//
//	&__inner div:nth-child(8) {
//		animation-delay: -0.288s;
//	}
//
//	&__inner div:nth-child(8)::after {
//		top: 56px;
//		left: 12px;
//	}
//	@keyframes spinner-animation {
//		0% {
//			transform: rotate(0deg);
//		}
//
//		100% {
//			transform: rotate(360deg);
//		}
//	}
//}

.loader {
	position: fixed;
	z-index: var(--overlay-z-index);
	top: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100vh;

	background-color: #fff;

	&__img {
		animation: spinning 1s linear infinite;
	}
}

@keyframes spinning {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
