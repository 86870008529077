.avatar {
	overflow: hidden;

	border-style: solid;
	border-width: 3px;
	border-radius: 100%;

	&_sm {
		width: 44px;
		height: 44px;
	}

	&_md {
		width: 80px;
		height: 80px;
	}

	&-border_white {
		border-color: #fff;
	}

	&-border_grey {
		border-color: var(--stroke-input);
	}

	&__img {
		width: 100%;
		height: 100%;
		border-radius: 100%;
		object-fit: cover;
	}
}