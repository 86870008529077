.radio {
	display: flex;
	align-items: center;

	width: fit-content;

	&__label {
		font-size: 14px;
		font-weight: 500;
	}

	&__input {
		position: absolute;

		visibility: hidden;
	}

	&__checkmark {
		position: relative;

		display: block;

		width: 20px;
		height: 20px;

		border: 2px solid var(--brand-blue);
		border-radius: 100%;
	}

	&__checkmark::after {
		content: "";

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(0);

		width: 12px;
		height: 12px;

		background-color: var(--txt-secondary);
		border-radius: 100%;

		transition: 0.1s ease-in-out;
	}

	&__input:checked + &__checkmark::after {
		transform: translate(-50%, -50%) scale(1);

		background-color: var(--brand-blue);
	}
}
