.checkbox {
	display: flex;
	align-items: center;

	&__label input:checked + &__checkmark {
		background-color: var(--brand-blue);
		border-color: var(--brand-blue);
	}

	&__label input:checked + &__checkmark &__ticket {
		transform: scale(1);

		background-color: var(--brand-blue);
	}

	&__checkmark {
		display: block;

		width: 18px;
		height: 18px;

		background-color: #f6f8fa;
		border: 1.5px solid #e1e8ee;
		border-radius: 4px;
	}

	&__ticket {
		position: relative;
		transform: scale(0);

		display: block;

		width: 100%;
		height: 100%;

		background-color: var(--brand-blue);
		border-radius: 4px;

		transition: 0.1s ease-in-out;
	}

	&__ticket::after {
		content: "";

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-45deg);

		width: 7px;
		height: 3px;

		border-bottom: 2px solid #fff;
		border-left: 2px solid #fff;
	}
}
