#modal-root {
	pointer-events: var(--modal-pointer-events);

	position: fixed;
	z-index: var(--modal-z-index);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	overflow-y: auto;

	padding: 20px 0;

	opacity: var(--modal-opacity);

	transition: 0.2s ease-in-out;
}

.modal {
	&__inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		min-height: calc(100vh - 40px);
	}

	&__wrapper {
		pointer-events: none;

		transform: translateY(-50px);

		width: max-content;

		opacity: 0;
		background-color: #fff;
		border-radius: 12px;
		box-shadow: 0 20px 24px -4px rgb(16 24 40 / 8%), 0 8px 8px -4px rgb(16 24 40 / 3%);

		transition: 0.2s ease-in-out;
	}

	&_open {
		pointer-events: initial;

		transform: translateY(0);

		opacity: 1;
	}

	&-default {
		position: relative;
	}

	&__close {
		position: absolute;
		top: 25px;
		right: 25px;
	}

	&-btn {
		display: block;

		width: 80%;
		margin-right: auto;
		margin-left: auto;
	}

	&-btn-end {
		display: block;

		width: 30%;
		margin-left: auto;
	}
}